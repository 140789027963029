import { useStore } from "@/lib/store";
import styles from "@/styles/Projects.module.css";
import request from "@/lib/requestClient";
import { format as timeAgo } from "timeago.js";
import { useEffect, useState } from "react";
import Head from "next/head";
import { Theme } from "@/stores/themes";
import { CodeBracketSquareIcon } from "@heroicons/react/20/solid";
import { classNames } from "@/lib/helpers";
import Link from "next/link";
import { ChevronLeftIcon, FilterIcon, SettingsIcon } from "lucide-react";
import { useRouter } from "next/router";
import ChatFlow from "@/components/projects/ChatFlowComponent";
import { ChatStep, ChatStepType } from "@/lib/types";
import PulseLoader from "react-spinners/PulseLoader";

interface Generation {
  active: boolean;
  statuses: string[];
  message: any;
  session: any;
  origin: {
    text: string;
    link: string | null;
  };
  project: any;
}

class BasicStep implements ChatStep {
  id: number;
  type: ChatStepType;
  text: string;
  upvote: boolean | null;

  constructor(
    id: number,
    type: ChatStepType,
    text: string,
    upvote?: boolean | null
  ) {
    this.id = id;
    this.type = type;
    this.text = text;
    this.upvote = upvote || null;
  }
}

export default function Dashboard() {
  const router = useRouter();
  const [themes, _updateThemes] = useStore<Theme[]>(
    "themesStore",
    ({ themes }) => themes
  );
  const [currentTheme, _setCurrentTheme] = useStore<number>(
    "themesStore",
    ({ currentTheme }) => currentTheme
  );
  const [theme, setTheme] = useState<Theme>(themes[0]);
  const [user, updateUser] = useStore("userStore", ({ user }) => user);
  const [organization, updateOrganization] = useStore(
    "userStore",
    ({ organization }) => organization
  );

  useEffect(() => {
    setTheme(themes[currentTheme]);
  }, [currentTheme, themes]);

  const [generations, setGenerations] = useState<Generation[]>([]);
  const [filteredGenerations, setFilteredGenerations] = useState<Generation[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(true);
  const [showChatModal, setShowChatModal] = useState(false);
  const [sortBy, setSortBy] = useState<
    "active" | "newest" | "oldest" | "project"
  >("active");
  const [filters, setFilters] = useState({
    ticket: true,
    prReview: true,
    bugScan: true,
    sdk: true,
    projects: {} as Record<string, boolean>,
  });
  const [selectedSession, setSelectedSession] = useState<{
    id: string;
    name: string;
    projectId: string;
    featureId: string;
  } | null>(null);
  const [sessionMessages, setSessionMessages] = useState<ChatStep[]>([]);
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);

  const fetchSessionMessages = async (
    projectId: string,
    featureId: string,
    sessionId: string
  ) => {
    if (!organization) return;
    setIsLoadingMessages(true);

    console.log(projectId, featureId, sessionId);

    try {
      const endpoint = request.endpoint(
        `organizations/${organization.id}/projects/${projectId}/features/${featureId}/chat/sessions/${sessionId}/list`
      );
      const response = await request.call(
        endpoint,
        request.createDefaultParams()
      );
      const messages = (await response.json()).map((msg: any) => {
        const chatStepType = msg.isAI ? ChatStepType.ai : ChatStepType.user;
        const step = new BasicStep(
          msg.id,
          chatStepType,
          msg.content,
          msg.feedbackUpvote
        );
        return step;
      });
      setSessionMessages(messages);
    } catch (error) {
      console.error("Failed to fetch session messages:", error);
    } finally {
      setIsLoadingMessages(false);
    }
  };

  const handleChatClick = (generation: Generation) => {
    const [projectId, featureId, sessionId] = generation.origin.link
      ?.substring(1)
      .split("/");
    setSelectedSession({
      id: sessionId,
      name: generation.origin.text,
      projectId,
      featureId,
    });
    setShowChatModal(true);
    fetchSessionMessages(projectId, featureId, sessionId);
  };

  // Apply filters and sorting to generations
  useEffect(() => {
    let filtered = [...generations];

    // Filter by type
    filtered = filtered.filter((gen) => {
      if (gen.session?.origin?.startsWith("ISSUE:") && !filters.ticket)
        return false;
      if (gen.session?.origin?.startsWith("GITHUB_PR:") && !filters.prReview)
        return false;
      if (gen.session?.origin?.startsWith("BUG_PERIODIC") && !filters.bugScan)
        return false;
      if (gen.session?.origin?.startsWith("API:") && !filters.sdk) return false;
      if (!filters.projects[gen.project.id]) return false;
      return true;
    });

    // Apply sorting
    filtered.sort((a, b) => {
      if (sortBy === "project") {
        return a.project.name.localeCompare(b.project.name);
      }
      if (sortBy === "active") {
        if (a.active && !b.active) return -1;
        if (!a.active && b.active) return 1;
      }
      return sortBy === "oldest"
        ? a.message.createdAt.localeCompare(b.message.createdAt)
        : b.message.createdAt.localeCompare(a.message.createdAt);
    });

    setFilteredGenerations(filtered);
  }, [generations, filters, sortBy]);

  // Initial fetch and setup refresh interval
  useEffect(() => {
    const fetchGenerations = async () => {
      if (!organization) return;

      const endpoint = request.endpoint(
        `organizations/${organization.id}/generations`
      );
      try {
        const response = await request.call(
          endpoint,
          request.createDefaultParams()
        );
        const data: Generation[] = await response.json();
        setGenerations(
          data.sort((a, b) => {
            if (a.active && !b.active) return -1;
            if (!a.active && b.active) return 1;
            return b.message.id - a.message.id;
          })
        );
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch generations:", error);
        setIsLoading(false);
      }
    };

    if (organization) {
      fetchGenerations();
      const interval = setInterval(fetchGenerations, 5000); // Refresh every 5 seconds
      return () => clearInterval(interval);
    }
  }, [organization]);

  // Initialize project filters when generations change, preserving existing states
  useEffect(() => {
    const projectIds = new Set(generations.map((gen) => gen.project.id));
    setFilters((prev) => {
      const existingProjects = prev.projects;
      const updatedProjects = Object.fromEntries(
        Array.from(projectIds)
          .sort()
          .map((id) => [
            id,
            // Preserve existing state or default to true for new projects
            existingProjects.hasOwnProperty(id) ? existingProjects[id] : true,
          ])
      );
      return {
        ...prev,
        projects: updatedProjects,
      };
    });
  }, [generations]);

  return (
    <>
      <Head>
        <title>Bismuth Dashboard</title>
        <meta name="description" content="View Bismuth's tasks" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <main className={styles.main}>
        <div className="flex grow flex-col h-screen w-screen">
          <div
            className="flex flex-col h-full overflow-hidden p-0"
            style={{
              backgroundColor: theme.editor.background,
              color: theme.ui.text,
            }}
          >
            <div
              className="flex flex-row justify-between pl-4 pr-4 text-center h-[42px] border-b-[1px] tracking-tight pb-4"
              style={{
                backgroundColor: theme.ui.titleBar,
                borderColor: theme.ui.borders,
                color: theme.ui.titleBarText,
              }}
            >
              <div className="flex-1 flex flex-row mt-4 items-center">
                <CodeBracketSquareIcon className="h-6 mr-2 fill-accent" />
                <p className="text-2xl">Dashboard</p>
              </div>
              <div className="flex-1 mt-1 text-right">
                <div className="flex flex-row items-center justify-end mt-0 mr-0 pr-0 h-8">
                  <p>{user ? user.name : ""}</p>
                  <button
                    className="btn btn-square btn-sm btn-ghost ml-2 mr-2"
                    onClick={() => router.push("/settings/billing")}
                  >
                    <SettingsIcon className="h-6" strokeWidth={"1px"} />
                  </button>
                </div>
              </div>
            </div>
            <div className="w-full h-full">
              {isLoading ? (
                <div className="flex items-center justify-center h-[calc(100vh-42px)]">
                  <div className="animate-spin rounded-full h-12 w-12 border-4 border-gray-300 border-t-blue-500" />
                </div>
              ) : (
                <div className="flex flex-row h-[calc(100vh-42px)] relative">
                  <div
                    className="hidden md:block md:w-64 flex-none border-r h-full overflow-hidden"
                    style={{
                      backgroundColor: theme.ui.tabsActive,
                      borderColor: theme.ui.borders,
                    }}
                  >
                    <div className="p-4 w-64">
                      <h3 className="text-lg font-medium mb-4 text-neutral-content">
                        Filters
                      </h3>

                      <div className="mb-6">
                        <label className="block text-sm font-medium mb-2 text-neutral-content">
                          Sort by
                        </label>
                        <select
                          value={sortBy}
                          onChange={(e) =>
                            setSortBy(
                              e.target.value as
                                | "active"
                                | "newest"
                                | "oldest"
                                | "project"
                            )
                          }
                          className="w-full bg-gray-700 text-neutral-content rounded-md py-1 px-2 border border-gray-600"
                        >
                          <option value="active">Is Active</option>
                          <option value="newest">Newest First</option>
                          <option value="oldest">Oldest First</option>
                          <option value="project">Project Name (A-Z)</option>
                        </select>
                      </div>

                      {/* Type Filters */}
                      <div className="mb-6">
                        <label className="block text-sm font-medium mb-2 text-neutral-content">
                          Type
                        </label>
                        <div className="space-y-2">
                          <label className="flex items-center text-neutral-content">
                            <input
                              type="checkbox"
                              checked={filters.ticket}
                              onChange={(e) =>
                                setFilters((prev) => ({
                                  ...prev,
                                  ticket: e.target.checked,
                                }))
                              }
                              className="mr-2"
                            />
                            Ticket
                          </label>
                          <label className="flex items-center text-neutral-content">
                            <input
                              type="checkbox"
                              checked={filters.prReview}
                              onChange={(e) =>
                                setFilters((prev) => ({
                                  ...prev,
                                  prReview: e.target.checked,
                                }))
                              }
                              className="mr-2"
                            />
                            PR Review
                          </label>
                          <label className="flex items-center text-neutral-content">
                            <input
                              type="checkbox"
                              checked={filters.bugScan}
                              onChange={(e) =>
                                setFilters((prev) => ({
                                  ...prev,
                                  bugScan: e.target.checked,
                                }))
                              }
                              className="mr-2"
                            />
                            Bug Scanning
                          </label>
                          <label className="flex items-center text-neutral-content">
                            <input
                              type="checkbox"
                              checked={filters.sdk}
                              onChange={(e) =>
                                setFilters((prev) => ({
                                  ...prev,
                                  sdk: e.target.checked,
                                }))
                              }
                              className="mr-2"
                            />
                            SDK
                          </label>
                        </div>
                      </div>

                      {/* Project Filters */}
                      <div>
                        <label className="block text-sm font-medium mb-2 text-neutral-content">
                          Projects
                        </label>
                        <div className="space-y-2">
                          {Object.entries(filters.projects).map(
                            ([projectId, checked]) => {
                              const project = generations.find(
                                (gen) => gen.project.id == projectId
                              )?.project;
                              return project ? (
                                <label
                                  key={projectId}
                                  className="flex items-center text-neutral-content"
                                >
                                  <input
                                    type="checkbox"
                                    checked={checked}
                                    onChange={(e) =>
                                      setFilters((prev) => ({
                                        ...prev,
                                        projects: {
                                          ...prev.projects,
                                          [projectId]: e.target.checked,
                                        },
                                      }))
                                    }
                                    className="mr-2"
                                  />
                                  {project.name}
                                </label>
                              ) : null;
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <ul
                    role="list"
                    className="max-h-full h-min w-full text-neutral-content flex flex-row flex-wrap gap-4 justify-center p-4 overflow-y-auto"
                  >
                    {filteredGenerations.map((generation) => {
                      return (
                        <li
                          key={generation.message.id}
                          style={{
                            backgroundColor: theme.ui.tabsActive,
                            borderLeft: generation.active
                              ? "4px solid #10B981"
                              : undefined,
                          }}
                          className="group h-min w-full max-w-md flex flex-col text-left rounded-lg px-4 py-2 text-neutral-content"
                        >
                          {generation.session?.origin?.startsWith(
                            "USER_CHAT:"
                          ) ? (
                            <div
                              className="text-lg text-gray-300 mb-1 cursor-pointer hover:text-blue-400"
                              onClick={() => handleChatClick(generation)}
                            >
                              {generation.origin.text}
                            </div>
                          ) : generation.origin.link ? (
                            <Link
                              href={generation.origin.link}
                              className="hover:text-blue-400"
                            >
                              {generation.origin.text}
                            </Link>
                          ) : (
                            generation.origin.text
                          )}
                          <Link
                            href={`/projects/${generation.project.id}`}
                            className="text-md text-gray-400 hover:text-blue-400"
                          >
                            {generation.project.name}
                          </Link>
                          <p className="text-sm text-gray-500 mb-1">
                            {timeAgo(
                              new Date(generation.message.createdAt + "Z")
                            )}
                          </p>
                          <div
                            className="relative mt-2 bg-gray-800 rounded-md p-2 overflow-hidden"
                            style={{
                              fontFamily:
                                'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
                            }}
                          >
                            <div
                              className="absolute top-0 left-0 right-0 h-6 bg-gradient-to-b from-gray-800 to-transparent"
                              style={{
                                display:
                                  generation.statuses.length > 3
                                    ? "block"
                                    : "none",
                              }}
                            />
                            <pre className="flex flex-col text-sm h-[4em] overflow-auto justify-end">
                              {generation.statuses.map((status, idx) => (
                                <span
                                  key={idx}
                                  className={classNames(
                                    idx === generation.statuses.length - 1 &&
                                      generation.active
                                      ? "text-emerald-400"
                                      : "text-gray-400"
                                  )}
                                >
                                  {status}
                                </span>
                              ))}
                            </pre>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>

      {/* Chat Modal */}
      {showChatModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <div className="inline-block align-bottom bg-neutral rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
              <div className="bg-neutral px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    <div className="flex justify-between items-center mb-4">
                      <h3 className="text-lg leading-6 font-medium text-neutral-content">
                        {selectedSession.name}
                      </h3>
                      <button
                        type="button"
                        className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                        onClick={() => {
                          setShowChatModal(false);
                          setSelectedSession(null);
                          setSessionMessages([]);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <svg
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>

                    {isLoadingMessages ? (
                      <div className="flex justify-center items-center h-96">
                        <PulseLoader size={10} color="white" />
                      </div>
                    ) : (
                      selectedSession && (
                        <ChatFlow
                          projectId={selectedSession.projectId}
                          featureId={selectedSession.featureId}
                          messages={sessionMessages}
                        />
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
