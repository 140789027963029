import { ChatStep, ChatStepType } from "@/lib/types";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Prism from "prismjs";
import {
  useEffect,
  useRef,
  useId,
  useMemo,
  useState,
  ReactElement,
} from "react";
import React from "react";
import { classNames } from "@/lib/helpers";

import "prismjs/themes/prism-okaidia.css"; // or any other theme you prefer

// Load the languages you need
import "prismjs/components/prism-python";
import {
  BookOpenIcon,
  Circle,
  FilePlusIcon,
  ThumbsUpIcon,
  ThumbsDownIcon,
  XIcon,
} from "lucide-react";
import { FaPython } from "react-icons/fa";
import request, { RequestMethod } from "@/lib/requestClient";
import { useStore } from "@/lib/store";

const NonPythonCode = ({
  code,
  className,
  ...rest
}: {
  code: string;
  className: string;
}) => {
  return (
    <code
      {...rest}
      className={classNames(className, "block overflow-x-hidden")}
    >
      {code}
    </code>
  );
};

interface CodeProps {
  children: string;
  className: string;
}

const Code = ({ children, className, ...rest }: CodeProps) => {
  const match = /language-(\w+)/.exec(className || "");

  const id = useId();

  if (match && (match[1] == "python" || match[1] == "markdown")) {
    const codeLines: string[] = (children || "").split("\n");
    const codeLinesWithContent = codeLines.filter((string) => string != "");
    const codeLinesCount = codeLinesWithContent.length;

    var pyCode = null;
    if (codeLinesCount <= 8) {
      pyCode = (
        <pre className={`language-${match[1]}`}>
          <code {...rest} className={className}>
            {children}
          </code>
        </pre>
      );
    } else {
      pyCode = (
        <>
          <div
            className="collapse-title flex flex-row"
            onClick={() => {
              (document.getElementById(id) as HTMLDialogElement).showModal();
            }}
          >
            <FaPython className="mr-2 fill-[#FFE873]" />
            <p className=" align-text-top">Long code, click to see...</p>
          </div>
          <dialog id={id} className="modal modal-middle">
            <div className="absolute top-auto rounded-lg overflow-auto max-h-screen">
              <pre className={`language-${match[1]} w-[1200px]`}>
                <code {...rest} className={className}>
                  {children}
                </code>
              </pre>
            </div>
            <form method="dialog" className="modal-backdrop">
              <button>close</button>
            </form>
          </dialog>
        </>
      );
    }
    return <div className="bg-[#272822] my-2 rounded-md">{pyCode}</div>;
  } else {
    return <NonPythonCode code={children} className={className} {...rest} />;
  }
};

interface MemoMarkdownProps {
  remarkPlugins: any[];
  components: any;
  text: string;
}

function MemoMarkdown({ remarkPlugins, components, text }: MemoMarkdownProps) {
  const md = useMemo(() => {
    return (
      <Markdown remarkPlugins={remarkPlugins} components={components}>
        {text}
      </Markdown>
    );
  }, [remarkPlugins, components, text]);

  return md;
}

interface ChatFeedbackProps {
  projectId: number;
  featureId: number;
  messageId: number;
  previousVote: boolean | null;
}

function ChatFeedback({
  projectId,
  featureId,
  messageId,
  previousVote,
}: ChatFeedbackProps) {
  const DownvoteReasons = [
    "Incorrect",
    "Didn't follow instructions",
    "Refused",
  ];

  const [upvote, setUpvote] = useState<boolean | null>(previousVote);
  const [organization, _setOrganization] = useStore(
    "userStore",
    ({ organization }) => organization
  );
  const [showExplanationModal, setShowExplanationModal] = useState(false);

  const sendFeedback = async (
    messageId: number,
    newUpvote: boolean | null,
    explanation?: string
  ) => {
    if (newUpvote === false && !explanation) {
      setShowExplanationModal(true);
    } else {
      setShowExplanationModal(false);
    }
    setUpvote(newUpvote);
    const endpoint = request.endpoint(
      `organizations/${organization.id}/projects/${projectId}/features/${featureId}/chat/feedback`
    );
    const requestParams = request.createDefaultParams(RequestMethod.POST);
    requestParams.body = JSON.stringify({
      messageId,
      upvote: newUpvote,
      explanation,
    });
    return await request.call(endpoint, requestParams);
  };

  return (
    <>
      <div className="flex flex-row mt-2 justify-end">
        <div
          className={classNames(
            "rounded-full p-1 pt-0 w-6 h-6 mr-2 flex justify-center cursor-pointer",
            upvote === true ? "bg-accent" : "bg-gray-600"
          )}
          onClick={async () => {
            if (upvote === true) {
              await sendFeedback(messageId, null);
            } else {
              await sendFeedback(messageId, true);
            }
          }}
        >
          <ThumbsUpIcon className="rounded-full fill-background" />
        </div>
        <div
          className={classNames(
            "rounded-full p-1 pt-0.5 w-6 h-6 mr-2 flex justify-center cursor-pointer",
            upvote === false ? "bg-red-600" : "bg-gray-600"
          )}
          onClick={async () => {
            if (upvote === false) {
              await sendFeedback(messageId, null);
            } else {
              await sendFeedback(messageId, false);
            }
          }}
        >
          <ThumbsDownIcon className="rounded-full fill-background" />
        </div>
      </div>
      {showExplanationModal ? (
        <div className="bg-base-200 rounded-lg p-2 mt-2 text-gray-300">
          <div className="flex flex-row justify-between mb-2">
            <h1 className="text-md">Tell us more</h1>
            <XIcon
              onClick={() => setShowExplanationModal(false)}
              className="w-4 h-4 cursor-pointer"
            />
          </div>
          <div className="flex flex-row flex-wrap">
            {DownvoteReasons.map((reason, index) => (
              <div
                key={index}
                className="btn btn-sm btn-outline m-1"
                onClick={async () => {
                  setShowExplanationModal(false);
                  await sendFeedback(messageId, false, reason);
                }}
              >
                {reason}
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
}

interface ChatFlowProps {
  projectId: number;
  featureId: number;
  messages: ChatStep[];
}

const ChatFlow: React.FC<ChatFlowProps> = ({
  messages,
  projectId,
  featureId,
}) => {
  useEffect(() => {
    // Highlight syntax whenever the component mounts or updates
    Prism.highlightAll();
  }, [messages]);

  const plugins = useMemo(() => [remarkGfm], []);

  const chatWindowRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollIntoView();
    }
  }, [messages.length]);

  const components = useMemo(
    () => ({
      code: Code,
      root: React.Fragment,
    }),
    []
  );

  const messagesLen = messages.length;
  const elements = messages.map((step, index) => {
    if (step.type == ChatStepType.user) {
      return (
        <div className="chat chat-start shadow-none p-2 pb-1 ml-0" key={index}>
          <div className="flex items-center justify-center rounded-full flex-shrink-0">
            <div className="w-full rounded-full">
              <Circle className="mx-auto h-10 w-auto drop-shadow-xl fill-secondary"></Circle>
            </div>
          </div>
          <div className="relative ml-0 text-secondary-content text-sm 2xl:text-md py-2 px-3 shadow-md bg-accent rounded-xl">
            {step.text}
          </div>
        </div>
      );
    } else {
      const isLastMessage = index == messagesLen - 1;
      return (
        <div className="chat chat-start shadow-none p-2 pb-1 ml-0" key={index}>
          <div className="flex items-center justify-center rounded-full w-10">
            <div className="w-full rounded-full">
              <Circle className="mx-auto h-10 w-auto drop-shadow-xl fill-primary"></Circle>
            </div>
          </div>
          {(step.text?.length || 0) > 0 ? (
            <div
              ref={isLastMessage ? chatWindowRef : null}
              className="relative ml-0 text-secondary-content text-sm 2xl:text-md py-2 px-3 shadow-md bg-neutral rounded-xl flex-auto"
            >
              <MemoMarkdown
                remarkPlugins={plugins}
                components={components}
                text={step.text}
              />
              <ChatFeedback
                projectId={projectId}
                featureId={featureId}
                messageId={step.id}
                previousVote={step.upvote}
              />
            </div>
          ) : null}
        </div>
      );
    }
  });

  return <div>{elements}</div>;
};

export default ChatFlow;
