export interface Props {
  children: React.ReactNode;
}

export interface UserProfile {
  data: Record<string, string>;
}

export interface Team {
  name: string;
  id: number;
}

export interface State extends Record<string, any> {}

export interface ComputedField {
  source: string;
  name: string;
  operation: CallableFunction;
}

export interface Store {}

export interface StoreFactory {
  createStore: (
    name: string,
    initialState: State,
    computedFields: ComputedField[]
  ) => Store;
  stores: Record<string, Store>;
  hook: () => (storeName: string, selector: (state: State) => any) => void;
}

export type Subscriber = (state: State) => void;

export type HeroIcon = React.ForwardRefExoticComponent<
  Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
    title?: string | undefined;
    titleId?: string | undefined;
  } & React.RefAttributes<SVGSVGElement>
>;

export interface User {
  id: number;
  name: string;
  email: string;
  pending: boolean;
}

export interface Organization {
  id: number;
  name: string;
  users: User[];
}

export interface Project {
  id: number;
  name: string;
  organizationId: number;
  features: Feature[];
  githubAppInstall: any;
  githubRepo: string | null;
}

export interface Feature {
  id: number;
  name: string;
  lastSavedChange: string;
  projectId: number;
}

export enum MediaType {
  image = 0,
  website = 1,
  plugin = 2,
}

export interface Span {
  length: number;
  start: number;
  end: number;
}

export interface Media {
  type: MediaType;
  data: string;
  span: Span;
}

export enum ChatStepType {
  user = 0,
  ai = 1,
}

export interface ChatStep {
  id: number;
  type: ChatStepType;
  text: string;
  upvote: boolean | null;
}

export interface ProgressStep {
  name: string;
  id: number;
}
