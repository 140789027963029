import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../tailwind.config";
import { useRef, useCallback } from "react";
import Dagre from "@dagrejs/dagre";
import { useEffect, useState } from "react";

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function devOrTest(): boolean {
  return (
    process.env.NODE_ENV == "development" || process.env.NODE_ENV == "test"
  );
}

export function getTailwindConfig() {
  const fullConfig = resolveConfig(tailwindConfig);

  return fullConfig;
}

// Define the type for any function
type AnyFunction = (...args: any[]) => void;

// The useDebounce hook takes a callback function and a delay in milliseconds
export function useDebounce<F extends AnyFunction>(callback: F, delay: number) {
  const timeoutRef = useRef<NodeJS.Timeout>();

  // Return a memoized version of the callback that only changes if the dependencies change
  return useCallback(
    (...args: any[]) => {
      const later = () => {
        clearTimeout(timeoutRef.current);
        callback(...args);

        timeoutRef.current = undefined;
      };

      if (timeoutRef.current) {
        return;
      }

      timeoutRef.current = setTimeout(later, delay);
    },
    [callback, delay]
  );
}

const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));

// https://stackoverflow.com/a/64218472/7078216
export const useCheckMobileScreen = () => {
  const [width, setWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : 0
  );
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    handleWindowSizeChange();

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return width <= 768;
};

export default useCheckMobileScreen;
